<template>
  <div class="fixed h-screen w-screen left-0 top-0 py-10 px-5 overflow-y-auto flex items-start justify-center bg-black bg-opacity-50" @click="close">
    <div class="relative bg-white rounded-lg shadow-lg max-w-[700px]" @click.stop>
      <div class="absolute top-3 left-5 z-10">
        <h1 class="text-2xl font-light text-white">{{ content.title }}</h1>
      </div>

      <gallery :images="images" />

      <div class="p-6 pt-3">
        <h2 class="text-2xl font-light mb-2 uppercase">Omschrijving</h2>
        <div class="product-description relative text-sm overflow-hidden mb-2" :class="showDescription ? 'h-auto' : 'h-28'" v-html="content.description"></div>
        <a v-if="!showDescription" href="#" @click="showDescription = true">Lees meer...</a>

        <hr class="mt-8 mb-6">

        <h2 class="text-2xl font-light uppercase mb-2">Kenmerken</h2>

        <div class="grid grid-cols-1 sm:grid-cols-2">
          <div class="mb-5">
            <h3 class="text font-semibold mb-2">Waarde</h3>
            <ul class="text-sm">
              <li>Vraagprijs: {{ content.display_price }}</li>
            </ul>

            <h3 class="text font-semibold mt-6 mb-2">Woninggegevens</h3>
            <ul class="text-sm">
              <li>Type: {{ content.construction_type }}</li>
              <li>Bouwjaar: {{ content.construction_year }}</li>
              <li>Woonoppervlakte: {{ content.surface }} m²</li>
              <li>Inhoud: {{ content.volume }} m³</li>
              <li>Perceeloppervlakte: {{ content.lot_surface }} m²</li>
            </ul>
          </div>

          <div>
            <h3 class="text font-semibold mb-2">Binnen</h3>
            <ul class="text-sm">
              <li>Kamers: {{ content.rooms }}</li>
              <li>Slaapkamers: {{ content.bedrooms }}</li>
            </ul>

            <h3 class="text font-semibold mt-6 mb-2">Buiten</h3>
            <ul class="text-sm">
              <li v-if="content.situation">Ligging: {{ content.situation }}</li>
              <li v-if="content.garden_location">Tuinligging: {{ content.garden_location }}</li>
            </ul>
          </div>
        </div>

        <hr class="mt-8 mb-6">

        <h2 class="text-2xl font-light uppercase mb-4">Locatie</h2>

        <iframe class="w-full h-96 border-none" loading="lazy" allowfullscreen referrerpolicy="no-referrer-when-downgrade" :src="mapsUrl"></iframe>
      </div>
      <back-to-recent-newsletter />
    </div>
  </div>
</template>

<script>
import Gallery from '@/components/Gallery'
import BackToRecentNewsletter from '@/components/BackToRecentNewsletter'

export default {
  components: { Gallery, BackToRecentNewsletter },
  props: {
    content: Object,
  },
  data () {
    return {
      showDescription: false,
    }
  },
  computed: {
    images () {
      return this.content.media.map(image => ({
        src: image.url,
        thumb: image.url,
      }))
    },
    mapsUrl () {
      return 'https://www.google.com/maps/embed/v1/place'
        + '?key=AIzaSyBANU6GB_-SLY1J6saBjyklEeSQ5s_7CnA'
        + `&q=${this.content.address}`
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
  }
}
</script>

<style>
.lg-backdrop {
  background-color: #fff;
}

.lg-outer .lg-thumb-outer {
  background: #fff;
}

.lg-object.lg-image {
  width: 100%;
  height: auto;
  max-height: unset;
}

.lg-outer .lg-thumb-item {
  border-radius: 0;
}

.lg-toolbar .lg-icon {
  color: rgba(255, 255, 255, 0.5);
}

.product-description::after {
  content: " ";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2rem;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
}
</style>
